import { Flex, FormErrorMessage, Image } from "@chakra-ui/react";

import warningTriangle from "@assets/img/warning-triangle.svg";

type Props = {
    message: string | undefined;
};

const ErrorMessage = ({ message }: Props) => (
    <FormErrorMessage
        as={Flex}
        gap={4}
        paddingBlock={4}
        paddingInline={5}
        borderRadius="xl"
        bg="red.100"
        lineHeight={7}
        marginTop={0}
        marginBottom={6}
    >
        <Image src={warningTriangle} alt="" width="1rem" />

        {message}
    </FormErrorMessage>
);

export default ErrorMessage;
